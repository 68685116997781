import { loadSessionId, saveNodesToFirebase, loadFromFirebase, checkSessionExists, saveNodePositionToFirebase, loadNeurolinks } from './database';
import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    // MiniMap,
    Background,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import MasterNode from './nodes/MasterNode';
import ContextMenu from './ContextMenu';
import FloatingToolbar from './FloatingTopBar';
import './App.css';
// import { setNodeRunningStatus } from './nodes/nodeStatus';
// import initNodes from './initNodes.json';
import GroupNode from './nodes/GroupNode';
import SearchComponent from './SearchComponent';

const originalWarn = console.warn;
console.warn = function (message, ...args) {
    if (typeof message === 'string' && message.includes('It looks like you have created a new nodeTypes or edgeTypes object')) {
        return;
    }
    originalWarn.apply(console, [message, ...args]);
};


const loadNodeSetups = async () => {
    const context = require.context('./nodeSetups', true, /\.json$/);
    const setups = {};

    context.keys().forEach((key) => {
        const nodeType = key.replace('./', '').replace('.json', '');
        setups[nodeType] = context(key);
    });
    await loadNeurolinks();

    return setups;
};

// Local Storage Handlers
const saveToLocalStorage = (nodes, edges) => {
    localStorage.setItem('nodes', JSON.stringify(nodes));
    localStorage.setItem('edges', JSON.stringify(edges));
};

const clearLocalStorage = () => {
    localStorage.removeItem('nodes');
    localStorage.removeItem('edges');
};


// File Download/Upload Handlers
const downloadJSON = (data, filename) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.lams`;
    link.click();
    URL.revokeObjectURL(url);
};

// Main App Component
const App = () => {
    const sessionId = loadSessionId();
    // State Management
    const [nodes, setNodes,] = useNodesState([]);
    const [edges, setEdges,] = useEdgesState([]);
    const [nodeSetups, setNodeSetups] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const reactFlowWrapper = useRef(null);
    const reactFlowInstance = useRef(null);
    const [copiedNode, setCopiedNode] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // Initial Setup: Loading Nodes and Edges
    useEffect(() => {
        const fetchNodeSetups = async () => {
            const setups = await loadNodeSetups();

            // Neurolinks aus Firebase laden
            const neurolinks = await loadNeurolinks();

            // Neurolinks filtern
            const filteredNeurolinks = Object.keys(neurolinks).reduce((acc, key) => {
                const neurolinkData = neurolinks[key];


                // Prüfen, ob das Objekt ein 'neurolink' enthält
                if (neurolinkData && neurolinkData.neurolink) {
                    acc[neurolinkData.neurolink.title.split(' - ')[0]] = neurolinkData.neurolink;
                }
                return acc;
            }, {});


            const combinedSetups = { ...setups, ...filteredNeurolinks };

            setNodeSetups(combinedSetups);
            loadFromFirebase(sessionId, setNodes, setEdges, isDragging); // Lade Daten aus Firebase
        };

        fetchNodeSetups();
    }, [setNodes, setEdges, sessionId, isDragging]);



    // Node Event Handlers
    const handleNodeChange = useCallback((e, field, nodeId) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId && node.data[field] !== e.target.value) {  // Zustand nur aktualisieren, wenn der Wert sich ändert
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            [field]: e.target.value,
                        },
                    };
                }
                return node;
            })
        );
    }, [setNodes]);


    const handleNodeClick = useCallback(
        (event, nodeId) => {
            setSelectedNodes((prevSelectedNodes) => {
                if (event.metaKey || event.shiftKey) {
                    return prevSelectedNodes.includes(nodeId)
                        ? prevSelectedNodes.filter((id) => id !== nodeId)
                        : [...prevSelectedNodes, nodeId];
                } else {
                    return [nodeId];
                }
            });
        },
        [setSelectedNodes]
    );

    const handleCanvasClick = useCallback(
        (event) => {
            closeContextMenu();

            if (
                reactFlowWrapper.current &&
                reactFlowWrapper.current.contains(event.target) &&
                !event.target.closest('.react-flow__node') &&
                !event.target.closest('.react-flow__edge')
            ) {
                setSelectedNodes([]); // Deselektiere alle Nodes
            }
        },
        [setSelectedNodes]
    );

    // Node Types Definition
    const nodeTypes = useMemo(() => ({
        masterNode: (props) => (
            <MasterNode
                {...props}
                edges={edges}
                setEdges={setEdges}
                setNodes={setNodes}
                sessionId={sessionId}
                nodes={nodes}
                data={{
                    ...props.data,
                    onChange: (e, field) => handleNodeChange(e, field, props.id),
                }}
                inputData={props.inputData}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),

        loopNode: (props) => (
            <MasterNode
                {...props}
                edges={edges}
                setEdges={setEdges}
                setNodes={setNodes}
                sessionId={sessionId}
                nodes={nodes}
                data={{
                    ...props.data,
                    onChange: (e, field) => handleNodeChange(e, field, props.id),
                }}
                inputData={props.inputData}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),

        groupNode: (props) => (
            <GroupNode
                {...props}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),
    }), [edges, setEdges, handleNodeChange, selectedNodes, handleNodeClick, nodes, sessionId, setNodes]);

    // Edge Connection Logic
    const onConnect = useCallback((params) => {
        const { sourceHandle, targetHandle } = params;

        const sourceType = sourceHandle.split('-')[1];
        const targetType = targetHandle.split('-')[1];

        const sourceNode = nodes.find(node => node.id === params.source);
        const targetNode = nodes.find(node => node.id === params.target);

        if (sourceNode && targetNode) {
            setEdges((eds) => {
                const updatedEdges = addEdge({ ...params, type: 'step', sourceHandle, targetHandle }, eds); // Füge die neue Edge hinzu
                saveNodesToFirebase(sessionId, nodes, updatedEdges, false); // Speichere die aktualisierten Nodes und Edges
                return updatedEdges; // Rückgabe der aktualisierten Edges
            });
        }

        if (!sourceNode || !targetNode) {
            return;
        }

        const sourcePort = sourceNode.data.setup.exports.find(port => port.type.toLowerCase() === sourceType.toLowerCase());
        const targetPort = targetNode.data.setup.imports.find(port => port.type.toLowerCase() === targetType.toLowerCase());


        targetNode.data[`${targetPort.type}_syn`] = sourceNode.id

        const isWhite = (color) => color === 'white' || color === '#ffffff';

        function updateNodeKey(nodes, sourceNode, targetPort) {

            setNodes(nodes);
        }

        updateNodeKey(nodes, sourceNode, targetPort)


        if (sourcePort?.color && targetPort?.color && (sourcePort.color === targetPort.color || isWhite(sourcePort.color) || isWhite(targetPort.color))) {
            setEdges((eds) => addEdge({ ...params, type: 'step' }, eds));
        } else {
        }
    }, [nodes, setEdges, sessionId, setNodes]);


    // Node Creation
    const handleSelectNode = useCallback((type) => {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.current.project({
            x: mousePosition.x - reactFlowBounds.left,
            y: mousePosition.y - reactFlowBounds.top,
        });

        const nodeSetup = nodeSetups[type];

        const newNode = {
            id: `${Date.now()}`,  // Eindeutige ID
            type: type === 'loopNode' ? 'loopNode' : 'masterNode',  // Prüfen, ob es ein LoopNode ist
            position,
            data: {
                setup: nodeSetup,
            },
        };

        setNodes((nds) => nds.concat(newNode));
        setContextMenu(null);
    }, [mousePosition.x, mousePosition.y, nodeSetups, setNodes]);


    // Download and Upload Handlers
    const handleDownload = () => {
        const state = { nodes, edges };
        downloadJSON(state, 'Neuroflow');
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];

        // Überprüfe, ob die Datei die Endung .lams hat
        if (!file.name.endsWith('.lams')) {
            console.error('Invalid file format. Please upload a .lams file.');
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            try {
                const { nodes, edges } = JSON.parse(e.target.result);

                // Leere den State und lösche den Local Storage
                setNodes([]);
                setEdges([]);
                clearLocalStorage();

                setTimeout(() => {
                    // Setze die hochgeladenen Nodes und Edges
                    setNodes(nodes);
                    setEdges(edges);
                    saveToLocalStorage(nodes, edges);
                    saveNodesToFirebase(sessionId, nodes, edges, isDragging);
                }, 100);
            } catch (error) {
                console.error('Error parsing .lams file:', error);
            }
        };
        fileReader.readAsText(file);
    };

    const saveSessionName = (id) => {
        const sessionName = prompt('Welchen Namen soll das neue Projekt erhalten?');
        if (!sessionName) {
            alert('Bitte gib einen gültigen Namen ein.');
            return;
        }

        let sessionData = JSON.parse(localStorage.getItem('sessionData')) || {};
        sessionData[id] = sessionName;
        localStorage.setItem('sessionData', JSON.stringify(sessionData));
    };

    const handleClearWorkflow = () => {
        setNodes([]);
        setEdges([]);
        clearLocalStorage();
        let newId = Date.now().toString();
        saveSessionName(newId);
        localStorage.setItem('sessionId', newId);
    };

    // Context Menu Handlers
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
        });
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };













    const handlePlay = async () => {
        console.log("Start of handlePlay");
        setIsPlaying(true);

        const dependencyMap = buildDependencyMap();
        const nodeMap = createNodeMap();


        const executionWorkflow = determineExecutionWorkflow(dependencyMap);

        const duplicateLogic = applyDuplicateLogic(executionWorkflow, nodeMap);

        if (duplicateLogic.length > 0) {
            await executeWorkflow(duplicateLogic, dependencyMap);
        } else {
            await executeWorkflow(executionWorkflow, dependencyMap);
        }
        setIsPlaying(false);
    };

    const applyDuplicateLogic = (executionWorkflow, nodeMap) => {
        // Schritt 1: Alle Loop-Nodes im Workflow finden
        const loopNodes = executionWorkflow.filter(node => {
            const nodeData = nodeMap.get(node.nodeId);  // Hole die Daten des Nodes aus nodeMap
            return nodeData && nodeData.data && nodeData.data.setup && nodeData.data.setup.function === 'loop.js';
        });

        // Schritt 2: Loop-Gruppen erstellen (mit loopNodeId als Key)
        const loopGroups = {};  // Verwende ein Objekt, um Gruppen nach loopNodeId zu speichern

        // Funktion zur rekursiven Verarbeitung von nextNodes, aber nur für die aktuelle Loop-Gruppe
        const collectGroupNodes = (nodeId, groupNodes = [], loopGroups, currentLoopNodeId, visitedNodes = new Set()) => {
            if (visitedNodes.has(nodeId)) {
                return groupNodes;  // Zirkuläre Abhängigkeiten vermeiden
            }
            visitedNodes.add(nodeId);

            const nodeData = nodeMap.get(nodeId);
            const allGroupNodes = Object.values(loopGroups).reduce((acc, group) => acc.concat(group.group), []);  // Alle Nodes aus anderen Loop-Gruppen

            // Füge die Abhängigkeiten des aktuellen Nodes zur Gruppe hinzu
            const dependencies = nodeData.dependencies || [];

            for (let depNodeId of dependencies) {
                if (allGroupNodes.some(node => node.nodeId === depNodeId)) {
                    continue;  // Überspringe bereits hinzugefügte Nodes
                } else {
                    collectGroupNodes(depNodeId, groupNodes, loopGroups, currentLoopNodeId, visitedNodes);
                }
            }

            // Füge den aktuellen Node zur Gruppe hinzu, wenn er noch nicht in der Gruppe ist
            if (!groupNodes.some(node => node.nodeId === nodeId)) {
                groupNodes.push({ nodeId: nodeId, nodeTitle: nodeData.data.setup.title, data: nodeData.data });
            }

            // Verarbeite die nextNodes, aber nur, wenn sie nicht aus einer anderen Loop-Gruppe stammen
            const nextNodes = nodeData.nextNodes || [];
            for (let nextNodeId of nextNodes) {
                // Überprüfen, ob der nächste Node nicht zu einem anderen Loop gehört
                const isPartOfOtherLoop = loopNodes.some(loopNode => loopNode.nodeId === nextNodeId && nextNodeId !== currentLoopNodeId);
                if (!isPartOfOtherLoop) {
                    // Verarbeite die nextNode rekursiv, nur innerhalb der aktuellen Gruppe
                    collectGroupNodes(nextNodeId, groupNodes, loopGroups, currentLoopNodeId, visitedNodes);
                }
            }

            return groupNodes;
        };

        // Schritt 3: Für jede Loop-Node eine Gruppe erstellen und mit loopNodeId als Key speichern
        for (let i = 0; i < loopNodes.length; i++) {
            const currentLoopNode = loopNodes[i];

            // Erstelle eine neue Gruppe für die Loop-Node und deren nextNodes
            const group = collectGroupNodes(currentLoopNode.nodeId, [], Object.values(loopGroups) || [], currentLoopNode.nodeId);  // Nur die nextNodes der aktuellen Loop sammeln

            // Speichere die Gruppe unter der loopNodeId
            loopGroups[currentLoopNode.nodeId] = {
                loopNode: {
                    nodeId: currentLoopNode.nodeId,
                    nodeTitle: currentLoopNode.nodeTitle,
                    data: currentLoopNode.data
                },
                group: group  // Die gesammelten abhängigen Nodes, nur für diese Loop-Node
            };
        }

        // Schritt 4: Vervielfachung der Nodes in einer Gruppe anhand des loop counts und Anpassung des loopContext + neues Objekt erstellen
        let groupKeys = Object.keys(loopGroups);
        let finalObject = [];  // Hier wird der finale Workflow gespeichert

        // Wir durchlaufen die Gruppen in umgekehrter Reihenfolge
        for (let i = groupKeys.length - 1; i >= 0; i--) {
            const currentLoopGroup = loopGroups[groupKeys[i]]?.group || [];
            let nextLoopGroup = loopGroups[groupKeys[i - 1]]?.group || [];
            const replaceNode = currentLoopGroup[1]?.nodeId;  // Der Node, der in nextLoopGroup ersetzt wird, falls vorhanden
            let replaceIndex = nextLoopGroup.findIndex(node => node.nodeId === replaceNode);

            // Schritt 1: Vervielfachen der aktuellen Gruppe (beginnend mit der innersten)
            const loopCount = parseInt(loopGroups[groupKeys[i]].loopNode.data['loop count']) || 1;
            let duplicatedNodes = [];  // Hier speichern wir die vervielfachten Nodes

            for (let loopIndex = 0; loopIndex < loopCount; loopIndex++) {
                currentLoopGroup.forEach(node => {
                    const duplicatedNode = {
                        ...node,
                        loopContext: {
                            ...(node.loopContext || {}),
                            [loopGroups[groupKeys[i]].loopNode.nodeId]: loopIndex + 1  // Setze den loopContext korrekt
                        }
                    };
                    duplicatedNodes.push(duplicatedNode);  // Vervielfachte Nodes in die Liste einfügen
                });
            }

            // Wenn wir bei der letzten (äußersten) Gruppe angekommen sind
            if (i === 0) {
                // Vervielfachen der letzten Gruppe basierend auf ihrem loopCount
                finalObject = [...duplicatedNodes];  // Vervielfachte Nodes in finalObject speichern
            } else {
                // Schritt 2: Ersetze den Node an replaceIndex in nextLoopGroup durch die vervielfachten duplicatedNodes
                if (replaceIndex !== -1) {
                    // Füge die duplicatedNodes an der Stelle von replaceIndex ein, indem wir den alten Node ersetzen
                    nextLoopGroup.splice(replaceIndex, 1, ...duplicatedNodes);
                }

                // Setze finalObject auf das aktualisierte nextLoopGroup
                finalObject = nextLoopGroup.map(node => ({
                    ...node,
                    loopContext: { ...(node.loopContext || {}) }  // loopContext explizit setzen
                }));
            }

            // Schritt 3: Erstellen des neuen finalObject, wenn es bereits existiert
            if (finalObject.length > 0 && i > 0) {
                let newObject = [];  // Erstellen eines neuen Objekts für die aktuelle Iteration
                const replaceNodeId = currentLoopGroup[0].nodeId;  // Wir verwenden den ersten Node, um den Platz zu bestimmen
                let replaceIndex = nextLoopGroup.findIndex(node => node.nodeId === replaceNodeId);
            
                if (replaceIndex !== -1) {
                    // Kopiere finalObject lokal, um die Referenzsicherheit zu gewährleisten
                    const localFinalObject = [...finalObject];
            
                    // Schritt 4: Ersetzen des Nodes in der aktuellen Gruppe durch das neue Objekt (localFinalObject)
                    nextLoopGroup.forEach((node, index) => {
                        if (index === replaceIndex) {
                            // Setze das neue Objekt (localFinalObject) an die richtige Stelle
                            newObject.push(...localFinalObject);
                        } else {
                            // Alle anderen Nodes in der aktuellen Gruppe werden unverändert hinzugefügt
                            newObject.push(node);
                        }
                    });
            
                    // Aktualisiere das finalObject mit der neuen Gruppe und übernehme den loopContext
                    finalObject = [...newObject.map(node => ({
                        ...node,
                        loopContext: { ...(node.loopContext || {}) }  // loopContext beibehalten
                    }))];
                }
            }
        }

        // Schritt 5: Finalen Workflow erstellen und den loopContext überall sicherstellen
        finalObject = finalObject.map(node => ({
            ...node,
            loopContext: { ...(node.loopContext || {}) }  // loopContext explizit setzen
        }));

        return finalObject;

    };





    const createNodeMap = () => {
        // Erstellt die Abhängigkeitskarte
        const dependencyMap = buildDependencyMap();

        // Erstellt die nodeMap
        const nodeMap = new Map();

        nodes.forEach(node => {
            const dependencies = dependencyMap.get(node.id) || [];
            const nextNodes = edges
                .filter(edge => edge.source === node.id)
                .map(edge => edge.target); // Alle Zielknoten, die mit dem aktuellen Knoten verbunden sind

            nodeMap.set(node.id, {
                id: node.id,
                data: node.data,
                position: node.position,
                type: node.type,
                dependencies: dependencies,   // Füge die Abhängigkeiten hinzu
                nextNodes: nextNodes // Füge alle verbundenen nächsten Knoten als Array hinzu
            });
        });

        return nodeMap;
    };

    // Funktion zum Erstellen der Abhängigkeitskarte
    const buildDependencyMap = () => {
        const dependencyMap = new Map();

        // Durchlaufe alle Nodes
        nodes.forEach(node => {
            const incomingEdges = edges.filter(edge => edge.target === node.id);
            const dependencies = incomingEdges.map(edge => edge.source);
            dependencyMap.set(node.id, dependencies);
        });


        return dependencyMap;
    };

    // const isNodeConnectedToLoop = (loopNodeId, otherNodeId, dependencyMap, visitedNodes = new Set()) => {
    //     if (loopNodeId === otherNodeId) {
    //         return true; // Wenn beide Knoten die gleichen sind, sind sie direkt verbunden
    //     }

    //     // Vermeide Zirkularität, indem du bereits besuchte Knoten speicherst
    //     if (visitedNodes.has(otherNodeId)) {
    //         return false; // Zirkuläre Abhängigkeit entdeckt, beende hier
    //     }

    //     visitedNodes.add(otherNodeId); // Markiere den aktuellen Knoten als besucht

    //     const dependencies = dependencyMap.get(otherNodeId) || [];

    //     // Rekursive Überprüfung, ob einer der Abhängigkeiten letztendlich zum Loop-Node führt
    //     for (let dep of dependencies) {
    //         if (isNodeConnectedToLoop(loopNodeId, dep, dependencyMap, visitedNodes)) {
    //             return true;
    //         }
    //     }

    //     return false; // Keine Verbindung gefunden
    // };


    // Funktion zur Ausführung des Workflows
    const determineExecutionWorkflow = (dependencyMap) => {
        const executionWorkflow = [];  // Endgültiger Workflow
        const executedNodes = new Set();  // Nodes, die schon ausgeführt wurden

        // Funktion zur Ausführung von Nodes, inklusive Abhängigkeiten
        const executeNode = (nodeId, loopContext = {}) => {
            const node = nodes.find(n => n.id === nodeId);
            const nodeTitle = node.data.setup.title;
            const dependencies = dependencyMap.get(nodeId) || [];


            // Behandle alle Abhängigkeiten zuerst
            dependencies.forEach(depNodeId => {
                if (!executedNodes.has(depNodeId)) {
                    executeNode(depNodeId, loopContext);  // Abhängigkeit ausführen
                }
            });

            // Jetzt führe den aktuellen Node aus
            if (!executedNodes.has(nodeId)) {
                executionWorkflow.push({
                    nodeId,
                    nodeTitle,
                    data: node.data
                });
                executedNodes.add(nodeId);  // Markiere den Node als ausgeführt
            }
        };

        // Starte die Ausführung mit den Nodes, die von anderen Nodes verwendet werden
        nodes.forEach(node => {
            const dependencies = dependencyMap.get(node.id) || [];
            if (dependencies.length > 0 && !executedNodes.has(node.id)) {
                executeNode(node.id);
            }
        });

        // Danach füge Nodes hinzu, die keine Abhängigkeiten haben, aber Teil der Datenlieferung sind
        nodes.forEach(node => {
            if (!executedNodes.has(node.id)) {
                executeNode(node.id);
            }
        });

        // Wende die Schleifenlogik auf den Workflow an
        return executionWorkflow;
    };


    // Funktion zur Ausführung des finalen Workflows
    const executeWorkflow = async (workflow, dependencyMap) => {
        const nodeResults = {}; // Hier speichern wir die Ergebnisse der Nodes
        const executedLoops = {}; // Speichert den Loop-Status für jeden Knoten
        let lastLoopId;
    
        // Helper function to load and execute each node's function
        const executeNodeFunction = async (nodeId, nodeSetup, inputData, step, loopIndex) => {
            try {
                Object.keys(inputData).forEach(key => {
                    Object.keys(step.data).forEach(key2 => {
                        if (step.data[key2] === key) {
                            inputData[key2.replace('_syn', '')] = inputData[key];
                            delete inputData[key];
                        }
                    });
                });
    
                // Dynamically import the function for the current node
                const nodeFunction = await import(`./nodeFunctions/${nodeSetup.function}`);
                const result = await nodeFunction.default({ node: step, nodeId, loopIndex, synaps: inputData });
    
                return result;
            } catch (error) {
                console.error(`Error executing node ${nodeId} - ${nodeSetup.title}:`, error);
                return { error: true, message: error.message };
            }
        };
    
        // Helper function to handle dependencies
        const getInputDataFromDependencies = (nodeId) => {
            const inputData = {};
            const dependencies = dependencyMap.get(nodeId) || [];
    
            dependencies.forEach(depNodeId => {
                if (nodeResults[depNodeId]) {
                    inputData[depNodeId] = nodeResults[depNodeId]; // Collect data from dependencies
                }
            });
            return inputData;
        };
    
        // Helper function to manage loop context
        const getLoopIndex = (step, loopContext, lastLoopId) => {
            let loopIndex = null;
            if (lastLoopId && loopContext && loopContext[lastLoopId] !== undefined) {
                loopIndex = loopContext[lastLoopId];
            }
            let loopContextKeys = Object.keys(loopContext || {});
            if (loopContext && loopContextKeys.length === 1) {
                loopIndex = loopContext[loopContextKeys[0]];
            }
            return loopIndex;
        };
    
        // Loop through each node in the workflow
        for (const step of workflow) {
            const { nodeId, loopContext } = step; // Hole den Loop-Kontext hier
    
            // Verwende den Loop-Kontext, um den Index zu setzen oder zurückzusetzen
            if (!executedLoops[nodeId]) {
                executedLoops[nodeId] = 0;
            } else {
                executedLoops[nodeId] = (executedLoops[nodeId] + 1) % step.loopCount;
            }
    
            const node = nodes.find(n => n.id === nodeId);
            const nodeSetup = node.data.setup;
    
            // Get input data from dependencies (previously executed nodes)
            const inputData = getInputDataFromDependencies(nodeId);
    
            if (step.nodeTitle.includes('Loop')) {
                lastLoopId = step.nodeId;
            }
    
            let loopIndex = getLoopIndex(step, loopContext, lastLoopId);
    
            // *** OPTIMIERUNG HIER: PARALLELE AUSFÜHRUNG ***
            // Execute the node's function and store the promise
            const promise = executeNodeFunction(nodeId, nodeSetup, inputData, step, loopIndex);
    
            // Wait for the node execution result and then store it
            promise.then((outputData) => {
                nodeResults[nodeId] = outputData;
    
                if (outputData.error) {
                    console.log(outputData);
                    return;
                }
            }).catch((error) => {
                console.error(`Error processing node ${nodeId}:`, error);
            });
    
            // ** Warte weiterhin auf andere abhängige Knoten, wenn nötig **
            await promise;  // Verhindere, dass die Schleife weitergeht, bis die aktuellen abhängigen Knoten verarbeitet wurden
        }
    
        console.log('üüpüüpüü');
        
        return nodeResults;
    };
    






























    const handleStop = () => {
        setIsPlaying(false);
    };

    const handleLogout = () => {
    };


    useEffect(() => {

        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {

        const handleMouseMove = (event) => {
            setMousePosition({
                x: event.clientX,
                y: event.clientY,
            });
        };

        const handleKeyDown = (event) => {
            // Überprüfe, ob der Fokus auf einem Eingabefeld oder einem anderen fokussierbaren Element liegt
            const activeElement = document.activeElement;
            const isInputFocused =
                activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable;

            // Wenn ein Eingabefeld fokussiert ist, blockiere die Shortcuts
            if (isInputFocused) {
                return;
            }

            // Deine Shortcut-Logik, wenn kein Eingabefeld fokussiert ist
            if (event.key === ' ') {
                setIsSearchOpen((prev) => !prev);
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
                const selectedNode = nodes.find(node => node.selected);
                if (selectedNode) {
                    setCopiedNode({ ...selectedNode, id: `${Date.now()}` });
                }
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'v' && copiedNode) {
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                const position = reactFlowInstance.current.project({
                    x: reactFlowBounds.width / 2,
                    y: reactFlowBounds.height / 2,
                });

                const newNode = {
                    ...copiedNode,
                    position,
                    id: `${Date.now()}`,
                };
                setNodes((nds) => nds.concat(newNode));
            }
        };


        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, copiedNode, setNodes, selectedNodes, isSearchOpen]);

    // const handleNodeDragStart = (event) => {
    //     setIsDragging(true); // Setzt den State auf "dragging"
    // };

    const handleNodeDragStop = (event, node) => {
        setTimeout(() => {
            setIsDragging(false); // Beendet den Dragging-Zustand
        }, 2000);
        saveNodesToFirebase(sessionId, nodes, edges); // Letzte Position speichern
    };

    const handleNodesDelete = useCallback(
        () => {
            if (selectedNodes.length === 0) {
                console.error("Keine Nodes ausgewählt zum Löschen");
                return;
            }

            // Lösche nur die ausgewählten Nodes
            const remainingNodes = nodes.filter((node) =>
                !selectedNodes.includes(node.id)
            );

            // Lösche die Edges, die mit den ausgewählten Nodes verbunden sind
            const remainingEdges = edges.filter((edge) =>
                !selectedNodes.includes(edge.source) && !selectedNodes.includes(edge.target)
            );

            // Aktualisiere den State
            setNodes(remainingNodes);
            setEdges(remainingEdges);

            // Aktualisiere die Daten in Firebase und im Local Storage
            saveNodesToFirebase(sessionId, remainingNodes, remainingEdges, isDragging);
            setSelectedNodes([]);  // Setze selectedNodes zurück, nachdem sie gelöscht wurden
        },
        [selectedNodes, nodes, edges, setNodes, setEdges, sessionId, isDragging]
    );



    const handleNodeDrags = (event, node) => {
        // Position des Nodes aktualisieren
        saveNodePositionToFirebase(sessionId, node.id, node);
    };


    const mouseLeave = (event, node) => {
        saveNodesToFirebase(sessionId, nodes, edges, false);  // Speichere Nodes und Edges in Firebase
        saveToLocalStorage(nodes, edges);
    };

    return (
        <div onClick={handleCanvasClick} className="app-container" onContextMenu={handleContextMenu}>
            <div className="main-content" ref={reactFlowWrapper}>
                <ReactFlowProvider>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodeMouseLeave={mouseLeave}
                        onConnect={onConnect}
                        fitView
                        nodeTypes={nodeTypes}
                        onInit={(rfi) => (reactFlowInstance.current = rfi)}
                        defaultZoom={0.1}
                        minZoom={0.2}
                        maxZoom={1.5}
                        onNodeDrag={handleNodeDrags}
                        onNodeDragStop={handleNodeDragStop}
                        onNodesDelete={handleNodesDelete}
                    >
                        <Background />
                    </ReactFlow>
                    {isSearchOpen && (
                        <SearchComponent
                            nodeSetups={nodeSetups}
                            onAddNode={handleSelectNode}
                            onClose={() => setIsSearchOpen(!isSearchOpen)}
                        />
                    )}
                </ReactFlowProvider>
                <ContextMenu
                    contextMenu={contextMenu}
                    onClose={closeContextMenu}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    onClear={handleClearWorkflow}
                    nodeSetups={nodeSetups}
                    onAddNode={handleSelectNode}
                />
                <FloatingToolbar
                    nodeCount={nodes.length}
                    onPlay={handlePlay}
                    onStop={handleStop}
                    onLogout={handleLogout}
                    onClearNodes={handleClearWorkflow}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    isPlaying={isPlaying}
                    loadFromFirebase={loadFromFirebase}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    checkSessionExists={checkSessionExists}
                    nodes={nodes}
                    edges={edges}
                />
            </div>
        </div>
    );
};

export default App;
