import React, { useState, useEffect, useRef } from 'react';
import './ContextMenu.css';

const ContextMenu = ({ contextMenu, onClose, onDownload, onUpload, nodeSetups, onAddNode, onClear }) => {
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [categories, setCategories] = useState({});
  const menuRef = useRef(null);

  useEffect(() => {
    const categorizedNodes = Object.keys(nodeSetups).reduce((acc, key) => {
        const category = nodeSetups[key].category || 'Uncategorized';
        const categoryName = category.charAt(0).toUpperCase() + category.slice(1);
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push({ key, title: nodeSetups[key].title });
        return acc;
    }, {});

    setCategories(categorizedNodes);
}, [nodeSetups]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleMouseEnter = (menu) => {
    setSubmenuOpen(menu);
  };

  const handleNodeClick = (key) => {
    onAddNode(key);
    onClose();
  };

  
  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.json';
    fileInput.onchange = onUpload;
    fileInput.click();
  };

  if (!contextMenu) {
    return null;
  }

  const menuStyle = {
    top: `${contextMenu.mouseY}px`,
    left: `${contextMenu.mouseX}px`,
  };

  return (
    <div
      className="context-menu"
      style={menuStyle}
      ref={menuRef}
    >
      <div className="context-menu-title">Menu</div>
      <ul>
        {Object.keys(categories).map((category, index) => (
          <li key={index} onMouseEnter={() => handleMouseEnter(category)}>
            {category}
            {submenuOpen === category && (
              <ul className="submenu">
                {categories[category].map(node => (
                  <li key={node.key} onClick={() => handleNodeClick(node.key)}>{node.title}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li onMouseEnter={() => handleMouseEnter('settings')}>
          Settings
          {submenuOpen === 'settings' && (
            <ul className="submenu">
              <li onClick={onDownload}>Download LAMS</li>
              <li onClick={handleUploadClick}>Upload LAMS</li>
              <li onClick={onClear}>Clear Workflow</li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default ContextMenu;
