import axios from 'axios';

/**
 * Schließt die angegebenen Instanzen durch das Senden einer POST-Anfrage an den Server.
 * @param {Array<string>} instanceIds - Ein Array von Instance-IDs, die geschlossen werden sollen.
 * @returns {Promise} - Ein Promise, das das Ergebnis der Anfrage zurückgibt.
 */
export const closeInstance = async (instanceIds) => {
  try {
    const response = await axios.post('https://server.lams-core.com/close', {
      instanceId: instanceIds,
    });
    console.log('Instanz(en) erfolgreich geschlossen:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Schließen der Instanz(en):', error);
    throw error;
  }
};
