import React, { useState, useEffect } from 'react';

const IframeComponent = ({ response, iframeId }) => {
  const [iframeUrl, setIframeUrl] = useState(localStorage.getItem(`iframeUrl_${iframeId}`) || '');

  // console.log(localStorage.getItem('liveUrl'));
  // console.log(response);
  
  useEffect(() => {
    // Nur aktualisieren, wenn die neue URL gültig ist
    if (response && response.includes('https://api.lams-core.com/stream/')) {
      console.log('Received URL for iframeId:', iframeId, response);
      setIframeUrl(response);
      localStorage.setItem(`iframeUrl_${iframeId}`, response); // URL im localStorage mit der iframeId speichern
    }
  }, [response, iframeId]);

  return (
    <div className="master-node-iframe-container">
      <iframe
        src={iframeUrl}
        title={`Iframe_${iframeId}`}
        className="master-node-iframe"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default IframeComponent;