import React, { useState, useRef, useEffect } from 'react';

const InputComponent = ({ value, onChange, placeholder, id }) => {
  const [internalValue, setInternalValue] = useState(value);
  const inputRef = useRef(null);

  // Aktualisiere den internen Zustand, wenn der externe Wert sich ändert
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Behandle lokale Änderung des Wertes im Input-Feld
  const handleChange = (event) => {
    const { selectionStart, selectionEnd } = event.target;
    const newValue = event.target.value;
    setInternalValue(newValue);  // Nur lokaler Zustand wird aktualisiert

    // Cursor-Position nach der Aktualisierung beibehalten
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    }, 0);
  };

  // Aktualisiere den externen Zustand nur bei Verlust des Fokus
  const handleBlur = (event) => {
    onChange(event);  // Externen Zustand aktualisieren bei Verlust des Fokus
  };

  return (
    <input
      id={id}
      ref={inputRef}
      type="text"
      placeholder={placeholder}
      value={internalValue}
      onChange={handleChange}  // Interner Zustand ändert sich
      onBlur={handleBlur}  // Externer Zustand wird bei `onBlur` aktualisiert
      className="master-node-input"
    />
  );
};

export default InputComponent;
