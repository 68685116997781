import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue } from 'firebase/database';

// Deine Firebase-Konfiguration
const firebaseConfig = {
    apiKey: "AIzaSyBOyI2LlGfbSSB40Z8HL89LezDkxpYst9k",
    authDomain: "lams-core.firebaseapp.com",
    databaseURL: "https://lams-core-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lams-core",
    storageBucket: "lams-core.appspot.com",
    messagingSenderId: "772267095274",
    appId: "1:772267095274:web:ad3b41020e41f99b4f1393",
    measurementId: "G-MNECGDYL84"
};

// Firebase-App initialisieren
const app = initializeApp(firebaseConfig);

// Datenbank aus der App exportieren
const database = getDatabase(app);

// let lastUploadTime = 0;
let lastDownloadTime = 0;
const delay = 2000; // 2 Sekunden
let isFirstLoad = true;

export const loadSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) {
        sessionId = Date.now().toString(); // Neue Session-ID generieren
        localStorage.setItem('sessionId', sessionId);
    }

    // Lade die Session-IDs Liste aus dem Local Storage
    let sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];

    // Überprüfe, ob die aktuelle Session-ID bereits in der Liste enthalten ist
    if (!sessionIds.includes(sessionId)) {
        sessionIds.push(sessionId); // Füge die neue Session-ID zur Liste hinzu
        localStorage.setItem('sessionIds', JSON.stringify(sessionIds)); // Speichere die Liste zurück in den Local Storage
    }

    return sessionId;
};


export const saveNodesToFirebase = (sessionId, nodes, edges, isDragging) => {
    if (!isDragging) {

        const sessionRef = ref(database, `sessions/${sessionId}`);

        const nodesWithoutFunctions = nodes.map(node => {
            const { onChange, ...dataWithoutFunctions } = node.data || {};
            return {
                ...node,
                data: dataWithoutFunctions,
            };
        });

        const nodesObject = {};
        nodesWithoutFunctions.forEach(node => {
            nodesObject[node.id] = node;
        });

        const edgesObject = {};
        edges.forEach(edge => {
            edgesObject[edge.id] = edge;
        });

        // Speichere die Daten zuerst lokal
        saveToLocalStorage(nodesObject, edgesObject);

        // Anschließend speichere die Daten in Firebase
        set(sessionRef, { nodes: nodesObject, edges: edgesObject });
    }
};

export const loadFromFirebase = (sessionId, setNodes, setEdges, isDragging) => {

    const currentTime = Date.now();
    if (!isDragging) {
        lastDownloadTime = currentTime;

        const sessionRef = ref(database, `sessions/${sessionId}`);
        onValue(sessionRef, (snapshot) => {
            if (!isDragging) {
                const data = snapshot.val();

                if (data && data.nodes) {
                    const { nodes: localNodesObject = {}, edges: localEdgesObject = {} } = loadFromLocalStorage();
                    const nodesAreEqual = JSON.stringify(localNodesObject) === JSON.stringify(data.nodes);
                    const edgesAreEqual = JSON.stringify(localEdgesObject) === JSON.stringify(data.edges);

                    if (isFirstLoad || !nodesAreEqual || !edgesAreEqual) {
                        setNodes(Object.values(data.nodes || {}));
                        setEdges(Object.values(data.edges || {}));
                        saveToLocalStorage(data.nodes || {}, data.edges || {});
                        isFirstLoad = false; // Nach dem ersten Ladevorgang Flag auf false setzen
                    }
                }
            }
        });
    } else {

    }
};

export const checkSessionExists = async (sessionId) => {
    const sessionRef = ref(database, `sessions/${sessionId}`);
    const snapshot = await get(sessionRef);
    return snapshot.exists();
};

export const saveToLocalStorage = (nodesObject, edgesObject) => {

    localStorage.setItem('nodes', JSON.stringify(nodesObject));
    localStorage.setItem('edges', JSON.stringify(edgesObject));
};

export const loadFromLocalStorage = () => {
    const nodes = JSON.parse(localStorage.getItem('nodes')) || {};
    const edges = JSON.parse(localStorage.getItem('edges')) || {};
    return { nodes, edges };
};

export const listenToNodesAndEdges = (sessionId, setNodes, setEdges, isDragging) => {
    const sessionRef = ref(database, `sessions/${sessionId}/nodes`);
    const edgesRef = ref(database, `sessions/${sessionId}/edges`);

    onValue(sessionRef, (snapshot) => {
        const currentTime = Date.now();
        if (!isDragging && currentTime - lastDownloadTime >= delay) {
            lastDownloadTime = currentTime;
            const data = snapshot.val();
            if (data) {
                setNodes(Object.values(data) || []);
            }
        }
    });

    onValue(edgesRef, (snapshot) => {
        const currentTime = Date.now();
        if (!isDragging && currentTime - lastDownloadTime >= delay) {
            lastDownloadTime = currentTime;
            const data = snapshot.val();
            if (data) {
                setEdges(Object.values(data) || []);
            }
        }
    });
};


export const saveNodePositionToFirebase = (sessionId, nodeId, node) => {
    const nodeRef = ref(database, `sessions/${sessionId}/nodes/${nodeId}`);
    set(nodeRef, node); // Speichere den gesamten Node in Firebase
};


// Neurolink lokal und in Firebase speichern (Upload)
export const saveNeurolinkToFirebase = (nodeSetup) => {
    // Erstelle eine eindeutige neurolinkID basierend auf der aktuellen Zeit und einem zufälligen Wert
    const neurolinkID = `nlink_${Date.now()}_${Math.random().toString(36).substr(2, 6)}`;
    nodeSetup.neurolink.nlinkId = neurolinkID;
    const neurolinkRef = ref(database, `neurolinks/${neurolinkID}`);

    // Zuerst lokal speichern
    saveNeurolinkToLocalStorage(neurolinkID, nodeSetup);

    // Dann in Firebase speichern
    set(neurolinkRef, nodeSetup)
        .then(() => {
            // console.log('Neurolink erfolgreich hochgeladen:', nodeSetup);
        })
        .catch((error) => {
            console.error('Fehler beim Hochladen des Neurolinks:', error);
        });
};

// Neurolink lokal und aus Firebase laden (Download)
export const loadNeurolinkFromFirebase = async (neurolinkID, callback) => {
    const neurolinkRef = ref(database, `neurolinks/${neurolinkID}`);

    // Zuerst lokal laden
    const localNeurolinkData = loadNeurolinkFromLocalStorage(neurolinkID);

    if (localNeurolinkData) {
        // console.log('Neurolink lokal gefunden:', localNeurolinkData);
        callback(localNeurolinkData);  // Wenn lokal gefunden, Rückgabe der Daten
    } else {
        try {
            // Wenn nicht lokal vorhanden, aus Firebase laden
            const snapshot = await get(neurolinkRef);
            if (snapshot.exists()) {
                const neurolinkData = snapshot.val();
                // console.log('Neurolink erfolgreich aus Firebase geladen:', neurolinkData);
                saveNeurolinkToLocalStorage(neurolinkID, neurolinkData); // Speichere die Daten lokal
                callback(neurolinkData);
            } else {
                // console.log('Neurolink nicht gefunden:', neurolinkID);
            }
        } catch (error) {
            console.error('Fehler beim Laden des Neurolinks:', error);
        }
    }
};


// **Neue Funktion**: Alle Neurolinks aus Firebase abrufen und lokal speichern
export const loadNeurolinks = async () => {
    const neurolinksRef = ref(database, 'neurolinks');

    try {
        // Abrufen aller Neurolinks aus Firebase
        const snapshot = await get(neurolinksRef);

        if (snapshot.exists()) {
            const allNeurolinks = snapshot.val();

            // Speichern aller Neurolinks lokal
            Object.keys(allNeurolinks).forEach((neurolinkID) => {
                saveNeurolinkToLocalStorage(neurolinkID, allNeurolinks[neurolinkID]);
            });

            return allNeurolinks;
        } else {
            return {};
        }
    } catch (error) {
        console.error('Fehler beim Abrufen aller Neurolinks aus Firebase:', error);
        return {};
    }
};

// Neurolink lokal speichern
const saveNeurolinkToLocalStorage = (neurolinkID, nodeSetup) => {
    const neurolinks = JSON.parse(localStorage.getItem('neurolinks')) || {};
    neurolinks[neurolinkID] = nodeSetup;

    localStorage.setItem('neurolinks', JSON.stringify(neurolinks));
};

// Neurolink lokal laden
const loadNeurolinkFromLocalStorage = (neurolinkID) => {
    const neurolinks = JSON.parse(localStorage.getItem('neurolinks')) || {};
    return neurolinks[neurolinkID] || null;
};