import React, { useState, useEffect, useCallback } from 'react';
import './FloatingTopBar.css';
import { getTotalRunningInstances, getRunningInstanceIds, clearAllInstances } from './instanceStorage';
import { closeInstance } from './instance';
import { createNeurolinkNode } from './NeurolinkManager';


const FloatingTopBar = ({ isPlaying, onPlay, onStop, nodeCount, onClearNodes, onDownload, onUpload, setEdges, setNodes, loadFromFirebase, checkSessionExists, nodes, edges }) => {
  const [totalLams, setTotalLams] = useState(0);
  const [showCoopBar, setShowCoopBar] = useState(false);
  const [isNetworkListVisible, setIsNetworkListVisible] = useState(false);
  const [sessionIdInput, setSessionIdInput] = useState('');
  const [activeSessionId, setActiveSessionId] = useState(localStorage.getItem('sessionId') || '');
  const [loading, setLoading] = useState(false); // Zustand für das Laden von Daten
  const [showNeurolinkBar, setShowNeurolinkBar] = useState(false);
  const [newNodeName, setNewNodeName] = useState('');



  // Toggle-Handler für COOP und Neurolink
  const handleToggleBar = (barType) => {
    if (barType === 'coop') {
      setShowCoopBar((prev) => !prev);
      setShowNeurolinkBar(false);  // Schließt die Neurolink Bar, wenn COOP geöffnet wird
    } else if (barType === 'neurolink') {
      setShowNeurolinkBar((prev) => !prev);
      setShowCoopBar(false);  // Schließt die COOP Bar, wenn Neurolink geöffnet wird
    }
  };

  const handleCreateNode = () => {
    // Ersetze Leerzeichen durch '-'
    const formattedName = newNodeName.replace(/\s+/g, '-');

    // Überprüfe, ob der Name mindestens 5 Zeichen hat
    if (formattedName.length < 5) {
      alert('Please enter a valid neuron name with at least 5 characters.');
      return;
    }

    // Logik, um den Node zu erstellen (später hinzufügen)
    createNeurolinkNode(formattedName, nodes, edges);

    // Reset der Eingabefelder und schließen der Neurolink-Bar
    setShowNeurolinkBar(false);
    setNewNodeName(''); // Zurücksetzen des Eingabefelds
  };



  const updateTotalLams = () => {
    setTotalLams(getTotalRunningInstances());
  };

  const toggleNetworkListVisibility = () => {
    setIsNetworkListVisible(prev => !prev);
  };

  const handleClearClick = useCallback(async () => {
    const instanceIds = getRunningInstanceIds();
    if (instanceIds.length > 0) {
      try {
        await closeInstance(instanceIds);
        clearAllInstances();
        updateTotalLams();
      } catch (error) {
        console.error('Fehler beim Schließen der Instanzen:', error);
      }
    }
  }, []);

  useEffect(() => {
    updateTotalLams();
  }, [isPlaying]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !isPlaying) {
        onPlay();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPlaying, onPlay]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        handleClearClick();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleClearClick]);

  const handlePlayClick = () => {
    if (!isPlaying) {
      onPlay();
    }
  };

  const handleStopClick = () => {
    if (isPlaying) {
      onStop();
    }
  };

  const handleNewLamsClick = () => {
    onClearNodes();
    window.location.reload();
  };

  const handleOpenLamsClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.lams';
    fileInput.onchange = (event) => {
      onUpload(event);
    };
    fileInput.click();
  };

  const handleConnectClick = async (sessionId) => {
    if (loading) return; // Verhindere das Starten eines neuen Ladevorgangs, wenn bereits ein Ladevorgang aktiv ist
    console.log(1);

    setLoading(true); // Setze den Zustand auf "Laden"
    let reload = false;

    try {
      const exists = await checkSessionExists(sessionId);
      if (exists) {
        let sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];
        if (!sessionIds.includes(sessionId)) {
          sessionIds.push(sessionId);
          localStorage.setItem('sessionIds', JSON.stringify(sessionIds));
        }

        // Leere aktuelle Nodes und Edges, bevor die neuen Daten geladen werden
        setNodes([]);
        setEdges([]);

        setActiveSessionId(sessionId);
        localStorage.setItem('sessionId', sessionId);

        // Lade die neuen Daten
        await loadFromFirebase(sessionId, setNodes, setEdges);
        reload = true;
      } else {
        alert('Session ID not found!');
      }
    } catch (error) {
      console.error('Fehler beim Laden der Session:', error);
    } finally {
      setLoading(false); // Beende den Ladevorgang
      if (reload) {
        window.location.reload();
      }
    }
  };

  const handleDeleteClick = (sessionId) => {
    let sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];
    sessionIds = sessionIds.filter(id => id !== sessionId);
    localStorage.setItem('sessionIds', JSON.stringify(sessionIds));

    if (sessionId === activeSessionId) {
      if (sessionIds.length > 0) {
        const nextSessionId = sessionIds[0];
        setActiveSessionId(nextSessionId);
        localStorage.setItem('sessionId', nextSessionId);
      } else {
        const newSessionId = `session-${Math.random().toString(36).substr(2, 9)}`;
        sessionIds.push(newSessionId);
        localStorage.setItem('sessionIds', JSON.stringify(sessionIds));
        setActiveSessionId(newSessionId);
        localStorage.setItem('sessionId', newSessionId);
      }
    }
    // window.location.reload();
  };

  // Funktion zum Abrufen des Namens basierend auf der ID aus 'sessionData'
  const getSessionName = (id) => {
    let sessionData = JSON.parse(localStorage.getItem('sessionData')) || {};
    return sessionData[id] || id; // Falls kein Name vorhanden ist, die ID als Fallback verwenden
  };


  return (
    <>
      <div className="floating-left-bar">
        <div className="system-title">
          LAMS <span className="version">v2.0.0_beta</span>
        </div>
        <button className="new-lams-button" onClick={handleNewLamsClick}>NEW NEURONET</button>
        <button className="open-lams-button" onClick={handleOpenLamsClick}>OPEN NEURONET</button>
        <button className="export-lams-button" onClick={onDownload}>EXPORT</button>
        <button className="documentation-button" disabled>DOCUMENTATION</button>
      </div>

      <div className="floating-top-bar">
        <button
          className={`play-button ${isPlaying ? 'processing' : ''}`}
          onClick={handlePlayClick}
          style={{
            backgroundColor: isPlaying ? '#e67e22' : '#27ae60',
          }}
        >
          {isPlaying ? 'LAMS IN PROCESS...' : 'RUN LAMS'}
        </button>
        <button
          className="stop-button"
          onClick={handleStopClick}
          disabled={!isPlaying}
        >
          STOP LAMS
        </button>
        <div className="separator"></div>
        <div>NEURON: {nodeCount}</div>
        <div className="separator"></div>
        <div>NETWORK: {totalLams}</div>
        <button
          className="small-clear-button"
          onClick={handleClearClick}
          disabled={totalLams === 0}
        >
          CLEAR
        </button>
      </div>

      {showCoopBar && (
        <div className="coop-bar">
          <input
            id='session-id-input'
            type="text"
            name='session-id'
            value={sessionIdInput}
            onChange={(e) => setSessionIdInput(e.target.value)}
            placeholder="Enter Session ID"
          />
          <button onClick={() => handleConnectClick(sessionIdInput)}>CONNECT</button>
        </div>
      )}

      {/* Neurolink Eingabe */}
      {showNeurolinkBar && (
        <div className="neurolink-bar">
          <input
            id='node-name-input'
            type="text"
            name='node-name'
            value={newNodeName}
            onChange={(e) => setNewNodeName(e.target.value)}
            placeholder="Enter Node Name"
          />
          <button onClick={handleCreateNode}>CREATE NEURON</button>
        </div>
      )}

      <div className={`floating-network-box ${isNetworkListVisible ? 'expanded' : 'minimized'}`}>
        <div className="network-box-header" onClick={toggleNetworkListVisibility}>
          NEURONETWORK
        </div>
        {isNetworkListVisible && (
          <ul className="session-list">
            {JSON.parse(localStorage.getItem('sessionIds') || '[]').map((id) => (
              <li
                key={id}
                className={id === activeSessionId ? 'active' : ''}

              >
                <div onClick={() => handleConnectClick(id)} style={{ width: "100%" }}>
                  <span >{getSessionName(id)}</span>
                </div>
                <span className="delete-icon" onClick={() => handleDeleteClick(id)}>✖</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="floating-bottom-bar">
        <button className="neuron-button" onClick={() => handleToggleBar('coop')}>
          COOP
        </button>
        <button className="neurolink-button" onClick={() => handleToggleBar('neurolink')}>
          CREATE NEUROLINK
        </button>
      </div>

      {/* Session ID unten links hinzufügen */}
      <div
        style={{ position: 'fixed', bottom: '10px', right: '10px', fontSize: '14px', color: '#ffffff', cursor: 'pointer', zIndex: 999 }}
        onClick={() => {
          navigator.clipboard.writeText(activeSessionId)
        }}
        title="Click to copy Session ID"
      >
        {activeSessionId}
      </div>
    </>
  );
};


export default FloatingTopBar;
