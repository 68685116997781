const instanceMap = new Map();

// Funktion, um die Instanzen aus dem lokalen Speicher zu laden
const loadInstanceMapFromLocalStorage = () => {
    const storedData = localStorage.getItem('instanceMap');
    if (storedData) {
        const parsedData = JSON.parse(storedData);
        Object.keys(parsedData).forEach(nodeId => {
            instanceMap.set(nodeId, parsedData[nodeId]);
        });
    }
};

// Funktion, um die Instanzen im lokalen Speicher zu speichern
const saveInstanceMapToLocalStorage = () => {
    const obj = {};
    instanceMap.forEach((value, key) => {
        obj['key'] = value;
    });
    localStorage.setItem('instanceMap', JSON.stringify(obj));
};

// Initialisiere die Instanzen-Map aus dem lokalen Speicher
loadInstanceMapFromLocalStorage();

export const setInstanceId = (nodeId, instanceId) => {
    if (instanceMap.has(nodeId)) {
        const instances = instanceMap.get(nodeId);
        if (Array.isArray(instances)) {
            instances.push(instanceId);
        } else {
            instanceMap.set(nodeId, [instances, instanceId]);
        }
    } else {
        instanceMap.set(nodeId, [instanceId]);
    }
    saveInstanceMapToLocalStorage(); // Speichert die aktualisierte Instanzen-Map
};

export const getInstanceId = (nodeId) => {
    const instances = instanceMap.get(nodeId);
    if (Array.isArray(instances)) {
        return instances[instances.length - 1];  // Gebe die zuletzt hinzugefügte Instanz zurück
    }
    return instances;
};

export const removeInstanceId = (nodeId) => {
    instanceMap.delete(nodeId);
    saveInstanceMapToLocalStorage(); // Speichert die aktualisierte Instanzen-Map
};

export const clearAllInstances = () => {
    instanceMap.clear();
    localStorage.removeItem('instanceMap'); // Löscht die Instanzen aus dem lokalen Speicher
};

export const getTotalRunningInstances = () => {
    let totalInstances = 0;
    instanceMap.forEach(instances => {
        totalInstances += Array.isArray(instances) ? instances.length : 1;
    });
    return totalInstances;
};

export const getInstanceIds = (nodeId) => {
    const instances = instanceMap.get(nodeId);
    return Array.isArray(instances) ? instances : [instances];
};

export const removeSpecificInstanceId = (nodeId, instanceId) => {
    if (instanceMap.has(nodeId)) {
        const instances = instanceMap.get(nodeId);
        if (Array.isArray(instances)) {
            const updatedInstances = instances.filter(id => id !== instanceId);
            if (updatedInstances.length > 0) {
                instanceMap.set(nodeId, updatedInstances);
            } else {
                instanceMap.delete(nodeId);
            }
        } else if (instances === instanceId) {
            instanceMap.delete(nodeId);
        }
        saveInstanceMapToLocalStorage(); // Speichert die aktualisierte Instanzen-Map
    }
};

export const getRunningInstanceIds = () => {
    const allInstanceIds = [];
    instanceMap.forEach(instances => {
        if (Array.isArray(instances)) {
            allInstanceIds.push(...instances);
        } else {
            allInstanceIds.push(instances);
        }
    });
    return allInstanceIds;
};
