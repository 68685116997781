// src/nodes/lib/DropdownComponent.js
import React from 'react';

const DropdownComponent = ({ value, onChange, options }) => (
  <select
    value={value}
    onChange={onChange}
    className="master-node-select"
  >
    {options.map((option, i) => (
      <option id={i} key={i} value={option}>
        {option}
      </option>
    ))}
  </select>
);

export default DropdownComponent;
