import React, { useState, useRef, useEffect } from 'react';

const KeyValueComponent = ({ keyValuePairs, onKeyChange, onValueChange, onAdd, onRemove }) => {
  const [internalKeyValuePairs, setInternalKeyValuePairs] = useState(keyValuePairs);
  const keyInputRefs = useRef([]);
  const valueInputRefs = useRef([]);

  useEffect(() => {
    setInternalKeyValuePairs(keyValuePairs);
  }, [keyValuePairs]);

  // Verarbeitet die lokale Änderung der Key-Werte
  const handleKeyChange = (event, kvIndex) => {
    const { selectionStart, selectionEnd } = event.target;
    const newKeyValuePairs = [...internalKeyValuePairs];
    newKeyValuePairs[kvIndex].key = event.target.value;
    setInternalKeyValuePairs(newKeyValuePairs);

    // Cursor-Position nach der Aktualisierung beibehalten
    setTimeout(() => {
      if (keyInputRefs.current[kvIndex]) {
        keyInputRefs.current[kvIndex].setSelectionRange(selectionStart, selectionEnd);
      }
    }, 0);
  };

  // Verarbeitet die lokale Änderung der Value-Werte
  const handleValueChange = (event, kvIndex) => {
    const { selectionStart, selectionEnd } = event.target;
    const newKeyValuePairs = [...internalKeyValuePairs];
    newKeyValuePairs[kvIndex].value = event.target.value;
    setInternalKeyValuePairs(newKeyValuePairs);

    // Cursor-Position nach der Aktualisierung beibehalten
    setTimeout(() => {
      if (valueInputRefs.current[kvIndex]) {
        valueInputRefs.current[kvIndex].setSelectionRange(selectionStart, selectionEnd);
      }
    }, 0);
  };

  // Externen Zustand nur bei Verlassen des Inputs aktualisieren (Key)
  const handleKeyBlur = (event, kvIndex) => {
    onKeyChange(event, kvIndex);  // Externen Zustand aktualisieren
  };

  // Externen Zustand nur bei Verlassen des Inputs aktualisieren (Value)
  const handleValueBlur = (event, kvIndex) => {
    onValueChange(event, kvIndex);  // Externen Zustand aktualisieren
  };

  return (
    <div>
      {internalKeyValuePairs.map((kv, kvIndex) => (
        <div key={kvIndex} className="master-node-key-value">
          <input
            ref={(el) => keyInputRefs.current[kvIndex] = el}
            id={`key-${kvIndex}_rfwu1`}
            type="text"
            placeholder="Enter Key"
            value={kv.key || ''}
            onChange={(e) => handleKeyChange(e, kvIndex)}  // Lokale Änderung
            onBlur={(e) => handleKeyBlur(e, kvIndex)}  // Externe Änderung bei Blur
            className="master-node-input"
          />
          :
          <input
            ref={(el) => valueInputRefs.current[kvIndex] = el}
            id={`value-${kvIndex}_1zjh`}
            type="text"
            placeholder="Enter Value"
            value={kv.value || ''}
            onChange={(e) => handleValueChange(e, kvIndex)}  // Lokale Änderung
            onBlur={(e) => handleValueBlur(e, kvIndex)}  // Externe Änderung bei Blur
            className="master-node-input"
          />
          <button className="master-node-remove-option" onClick={() => onRemove(kvIndex)}>
            &times;
          </button>
        </div>
      ))}
      <button className="master-node-add-option" onClick={onAdd}>
        Add Option
      </button>
    </div>
  );
};

export default KeyValueComponent;
