import React from 'react';
import { Handle } from 'react-flow-renderer';

const GroupNode = ({ id, data, isSelected }) => {
  const nodeStyle = {
    border: '2px solid #34495e',  // Dunklere Umrandung für gruppierte Nodes
    backgroundColor: isSelected ? '#1abc9c' : '#2c3e50',
    color: '#ffffff',
    padding: '10px',
    position: 'relative',
    width: `${data.width}px`,
    height: `${data.height}px`,
  };

  return (
    <div style={nodeStyle}>
      <div className="topbar">
        GROUP
      </div>
      {/* Falls Handles nötig sind */}
      <Handle type="target" position="left" />
      <Handle type="source" position="right" />
    </div>
  );
};

export default GroupNode;
