import React, { useState, useRef, useEffect } from 'react';

const TextareaComponent = ({ value, onChange, placeholder }) => {
  const [internalValue, setInternalValue] = useState(value);
  const textareaRef = useRef(null);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (event) => {
    const { selectionStart, selectionEnd } = event.target;
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange(event); // Externen Zustand aktualisieren

    // Cursor-Position nach der Aktualisierung beibehalten
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    }, 0);
  };

  return (
    <textarea
      id="textarea"
      ref={textareaRef}
      placeholder={placeholder}
      value={internalValue}
      onChange={handleChange}
      className="master-node-textarea"
    />
  );
};

export default TextareaComponent;
