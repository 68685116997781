// NeurolinkManager.js

import { saveNeurolinkToFirebase } from "./database";

let savedNetwork = null; // Variable zum Speichern des Workflows

// Funktion zum Erstellen eines neuen Neurolink-Nodes
export const createNeurolinkNode = (nodeName, nodes, edges) => {
    // Speichere den aktuellen Workflow (Nodes und Edges) in einer Variable
    savedNetwork = {
        nodeName: nodeName,
        nodes: nodes,
        edges: edges
    };

    // Ermitteln der Import- und Export-Punkte
    const imports = [];
    const exports = [];

    // 1. Alle Neurolink-Nodes raussuchen
    const neurolinkNodes = nodes.filter((node) => node.data.setup.title === "NeuroLink - (Special Functions Neuron)");

    neurolinkNodes.forEach((neurolinkNode) => {
        // 2. Gehe alle Verbindungen (Edges) durch, um zu sehen, ob der Neurolink als Source oder Target verwendet wird
        edges.forEach((edge) => {
            if (edge.source === neurolinkNode.id) {
                // Neurolink ist als Source verbunden (d.h. Export-Punkt des Neurolinks ist belegt)
                const connectedTargetNode = nodes.find((node) => node.id === edge.target);

                if (connectedTargetNode && connectedTargetNode.data.setup.imports) {
                    // 3. Wenn der Import-Punkt des Neurolinks belegt ist, pushe die Daten des Export-Punkts des Neurolinks
                    neurolinkNode.data.setup.exports.forEach((exportPoint) => {
                        imports.push({
                            title: neurolinkNode.data.title,
                            type: exportPoint.type,
                            color: exportPoint.color
                        });
                    });
                }
            } else if (edge.target === neurolinkNode.id) {
                // Neurolink ist als Target verbunden (d.h. Import-Punkt des Neurolinks ist belegt)
                const connectedSourceNode = nodes.find((node) => node.id === edge.source);

                if (connectedSourceNode && connectedSourceNode.data.setup.exports) {
                    // 3. Wenn der Export-Punkt des Neurolinks belegt ist, pushe die Daten des Import-Punkts des Neurolinks
                    neurolinkNode.data.setup.imports.forEach((importPoint) => {
                        exports.push({
                            title: neurolinkNode.data.title,
                            type: importPoint.type,
                            color: importPoint.color
                        });
                    });
                }
            }
        });
    });

    // Erstellen und Herunterladen der nodeSetup.json
    createNodeSetupFile(nodeName, imports, exports);
};

// Funktion, um den gespeicherten Workflow abzurufen
export const getsavedNetwork = () => {
    return savedNetwork;
};

// Funktion zum Erstellen und Herunterladen der nodeSetup.json
export const createNodeSetupFile = (nodeName, imports, exports) => {
    // Setup-Objekt für die Node-Erstellung
    const nodeSetup = {
        title: `${nodeName} - (Privat Neurolink)`, // Dynamischer Titel basierend auf dem Node-Namen
        category: "Neurolink",
        topbarColor: "#007BFF", // Setzt eine Standardfarbe, kann angepasst werden
        description: "",
        function: "runWorkflow.js", // Funktion, die den Workflow ausführt
        width: "500px", // Standardbreite für die Node
        items: [
            {
                id: 2,
                type: "keyValue",
                title: "Options:"
            }
        ],
        imports: imports.map((imp) => ({
            type: imp.title.toLowerCase(),
            title: imp.title,
            color: imp.color
        })),
        exports: exports.map((exp) => ({
            type: exp.title.toLowerCase(),
            title: exp.title,
            color: exp.color
        }))
    };

    // Erstelle den JSON-String

    saveNeurolinkToFirebase({ neurolink: nodeSetup, network: savedNetwork });

      // Neuladen der Seite, nachdem der Neurolink gespeichert wurde
      setTimeout(() => {
        window.location.reload();
    }, 500); // Kleine Verzögerung für eine sichere Speicherung
};
