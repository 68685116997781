import React, { useState, useEffect } from 'react';

const LogComponent = ({ logData, logId }) => {
  const [logs, setLogs] = useState(localStorage.getItem(`logData_${logId}`) || 'Wait');

  useEffect(() => {
    if (logData) {
      // console.log('Received logData for logId:', logId, logData);
      setLogs(logData);
      localStorage.setItem(`logData_${logId}`, JSON.stringify(logData)); // Logs als JSON speichern
    }
  }, [logData, logId]);

  return (
    <div>
      <h4>Log Output:</h4>
      {/* Falls logData ein Objekt ist, kannst du es wie folgt darstellen */}
      <pre id={`log_${logId}`}>
        {typeof logs === 'object' ? JSON.stringify(logs, null, 2) : logs}
      </pre>
    </div>
  );
};

export default LogComponent;
